import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CookiePolicyService } from 'src/app/services/cookie/cookie-policy.service';

@Component({
    selector: 'app-cookie-settings',
    templateUrl: './cookie-settings.component.html',
    styleUrls: ['./cookie-settings.component.scss'],
})
export class CookieSettingsComponent {
    privacyForm = this.getFormGroup();

    constructor(
        private cookiePolicyService: CookiePolicyService,
        private dialogRef: MatDialogRef<CookieSettingsComponent>
    ) {
        const cookiePolicyAgreement =
            this.cookiePolicyService.getCookiePolicyAgreement();
        this.privacyForm
            .get('statistics')
            ?.setValue(
                cookiePolicyAgreement
                    ? cookiePolicyAgreement.settings.statistics
                    : true
            );
    }

    savePrivacySettings() {
        const agreement = {
            settings: {
                statistics: !!this.privacyForm.get('statistics')!.value,
            },
        };
        this.cookiePolicyService.setCookiePolicyAgreement(agreement);
        this.dialogRef.close();
    }

    private getFormGroup(): FormGroup<{
        essential: FormControl<boolean | null>;
        statistics: FormControl<boolean | null>;
    }> {
        return new FormGroup<{
            essential: FormControl<boolean | null>;
            statistics: FormControl<boolean | null>;
        }>({
            essential: new FormControl<boolean | null>({
                value: true,
                disabled: true,
            }),
            statistics: new FormControl<boolean | null>(false),
        });
    }
}
