import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FaqPageComponent } from '../../components/faq-page/faq-page.component';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog
    ) {}

    onLampClick(): void {
        this.router.navigate(['../survey'], { relativeTo: this.route });
    }

    openFAQ(): void {
        this.dialog.open(FaqPageComponent, {
            width: 'calc(100vw - 32px)',
            maxWidth: '100vw',
            height: 'calc(100vh - 32px)',
            maxHeight: '95vh',
        });
    }
}
