<div class="header-container">
    <button mat-button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
        Back
    </button>
    <div class="logo-container">
        <div class="logo-wrapper">
            <a href="https://www.altruist.dev/" target="_blank">
                <img
                    src="../../../assets/icons/altruist/logo-gray.svg"
                    alt="Altruist Logo"
                    class="logo default-logo"
                />

                <img
                    src="../../../assets/icons/altruist/logo-colored.svg"
                    alt="Altruist Logo Colored"
                    class="logo colored-logo"
                />
            </a>
        </div>
    </div>
    <button mat-button class="FAQ-button" (click)="openFAQ()">FAQ</button>
</div>
