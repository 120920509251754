<div class="copyright">
    <div>Copyright © 2023 Altruist Volunteer Story Genie</div>
    <div>
        <a
            class="link-tag-styles"
            href="https://www.altruist.dev/privacy"
            target="_blank"
            >Privacy</a
        >
        //
        <a
            class="link-tag-styles"
            href="https://www.altruist.dev/terms"
            target="_blank"
            >Legal</a
        >
    </div>
</div>
