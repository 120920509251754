// Angular imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// Components
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { CookieSettingsComponent } from './cookie-settings/cookie-settings.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { EmailDialogComponent } from './email-dialog/email-dialog.component';
import { FaqButtonComponent } from './faq-button/faq-button.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { HeaderComponent } from './header/header.component';
import { LoadingComponent } from './loading/loading.component';
import { NextButtonComponent } from './next-button/next-button.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FaqButtonComponent,
        CopyrightComponent,
        NextButtonComponent,
        ProgressBarComponent,
        LoadingComponent,
        FaqPageComponent,
        EmailDialogComponent,
        CookieBannerComponent,
        CookieSettingsComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatExpansionModule,
        MatRippleModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        HeaderComponent,
        FaqButtonComponent,
        CopyrightComponent,
        NextButtonComponent,
        ProgressBarComponent,
        LoadingComponent,
        EmailDialogComponent,
        CookieBannerComponent,
        CookieSettingsComponent,
        MatSlideToggleModule,
    ],
})
export class SharedModule {}
