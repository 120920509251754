import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ResponseObject } from 'src/app/models/response-obj.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ResponseService {
    response = new BehaviorSubject<ResponseObject>({} as ResponseObject);

    constructor(private http: HttpClient) {}

    updateResponse(input: Partial<ResponseObject>): void {
        const currInput = this.response.getValue();
        const updated = { ...currInput, ...input };
        this.response.next(updated);
    }

    buildPrompt(userAnswers: any): string {
        // Locked answers part
        let prompt =
            `Tell a volunteering story about someone named ${userAnswers.name} ` +
            `and their friend ${userAnswers.friend} who volunteer doing ${userAnswers.activity} ` +
            `for an organization called ${userAnswers.nonprofit}`;

        // injections for random answers
        if (userAnswers.random.R1) prompt += ` in ${userAnswers.random.R1}`;
        if (userAnswers.random.R2)
            prompt += ` after going to see the band ${userAnswers.random.R2} the night before`;
        if (userAnswers.random.R3)
            prompt += `. ${userAnswers.random.R3} shows up to the volunteering event`;
        if (userAnswers.random.R4)
            prompt += `. They find a ${userAnswers.random.R4}`;
        if (userAnswers.random.R5)
            prompt += ` and lose an important ${userAnswers.random.R5}`;
        if (userAnswers.random.R6)
            prompt += `. After volunteering they decide to go see the movie ${userAnswers.random.R6}`;

        // final mood and word limit.
        prompt += `. Tell the story in a ${userAnswers.mood} tone. Limit the story to 200 words.`;

        return prompt;
    }

    getGptResponse(prompt: string): Observable<any> {
        const data = {
            model: 'gpt-3.5-turbo',
            messages: [{ role: 'system', content: prompt }],
        };

        const apiUrl = `${environment.apiUrl}/api/story`;

        return this.http.post<any>(apiUrl, data).pipe(
            catchError((err) => {
                return throwError(() => err.status);
            })
        );
    }

    moderateContent(content: string): Observable<any> {
        const data = {
            input: content,
        };

        const apiUrl = `${environment.apiUrl}/api/prompt-moderation`;

        return this.http.post<any>(apiUrl, data).pipe(
            catchError((err) => {
                return throwError(() => err.status);
            })
        );
    }

    checkCostLimit(): Observable<any> {
        const apiUrl = `${environment.apiUrl}/api/have-tokens`;

        return this.http.get<any>(apiUrl).pipe(
            catchError((err) => {
                return throwError(() => err.status);
            })
        );
    }
}
