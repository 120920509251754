<div class="loading-container">
    <div class="content">
        <div class="genie-container">
            <img
                src="../../../../assets/icons/characters/Genie.svg"
                class="genie-character"
            />
        </div>
        <div class="title">
            Its story magic time! Get cozy and get ready to read
        </div>
        <div class="spinner-container">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
