import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CookiePolicyAgreement } from 'src/app/models/cookie-policy-agreement.model';

@Injectable({
    providedIn: 'root',
})
export class CookiePolicyService {
    private cookiePolicyChangedSubject = new Subject<CookiePolicyAgreement>();
    cookiePolicyChanged$ = this.cookiePolicyChangedSubject.asObservable();

    private readonly COOKIE_POLICY_KEY = 'alt_cookie_policy_agreement';

    constructor() {}

    getCookiePolicyAgreement() {
        const storageValue = localStorage.getItem(this.COOKIE_POLICY_KEY);
        return storageValue
            ? (JSON.parse(storageValue) as CookiePolicyAgreement)
            : null;
    }

    setCookiePolicyAgreement(cookiePolicyAgreement: CookiePolicyAgreement) {
        localStorage.setItem(
            this.COOKIE_POLICY_KEY,
            JSON.stringify(cookiePolicyAgreement)
        );
        this.cookiePolicyChangedSubject.next(cookiePolicyAgreement);
    }
}
