import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { ResponseService } from 'src/app/services/response/response.service';

@Component({
    selector: 'app-next-button',
    templateUrl: './next-button.component.html',
    styleUrls: ['./next-button.component.scss'],
})
export class NextButtonComponent {
    @Output() onClickEvent = new EventEmitter<boolean>();
    constructor(public responseService: ResponseService) {}

    onClick(): void {
        this.onClickEvent.emit(true);
    }
}
