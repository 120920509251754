<div class="home-container">
    <div class="header-container">
        <div class="logo-container">
            <div class="logo-wrapper">
                <a href="https://www.altruist.dev/" target="_blank">
                    <img
                        src="../../../assets/icons/altruist/logo-gray.svg"
                        alt="Altruist Logo"
                        class="logo default-logo"
                    />

                    <img
                        src="../../../assets/icons/altruist/logo-colored.svg"
                        alt="Altruist Logo Colored"
                        class="logo colored-logo"
                    />
                </a>
            </div>
        </div>
        <faq-button class="faq" (click)="openFAQ()">FAQ</faq-button>
    </div>
    <div class="content">
        <div class="title">Click the lamp to release the story genie</div>
        <div class="lamp-container">
            <img
                src="../../../assets/icons/characters/Lamp.svg"
                class="lamp-character"
                (click)="onLampClick()"
            />
        </div>
        <div class="subtitle">
            By continuing, you will be interacting with an AI tool
        </div>
    </div>
    <app-copyright></app-copyright>
</div>
