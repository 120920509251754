import { Component, ViewChild } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    NgForm,
    Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ResponseService } from '../../services/response/response.service';
import { FormErrorMatcher } from '../form-error-matcher.component';

@Component({
    selector: 'app-email-dialog',
    templateUrl: './email-dialog.component.html',
    styleUrls: ['./email-dialog.component.scss'],
})
export class EmailDialogComponent {
    @ViewChild('form') formElement: NgForm;
    inputControl: FormControl = new FormControl('', [
        Validators.required,
        this.emailValidator,
    ]);
    matcher = new FormErrorMatcher();

    email: string;

    constructor(
        public dialogRef: MatDialogRef<EmailDialogComponent>,
        public responseSvcs: ResponseService
    ) {}

    closeClick(): void {
        this.formElement.onSubmit(undefined);
        if (this.inputControl.valid) {
            this.responseSvcs.updateResponse({
                email: this.inputControl.value,
            });

            this.dialogRef.close();
        }
    }

    emailValidator(control: AbstractControl): { [key: string]: any } | null {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const valid = emailRegex.test(control.value);
        return valid ? null : { invalidEmail: { value: control.value } };
    }
}
