import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
    questionNumber: number;
    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.questionNumber = +this.route.snapshot.url.join().split('/').at(-1);
    }
}
