<div class="popup app-dialog">
    <header>
        <span>Cookie Settings</span>
        <button
            type="button"
            class="dialog-close"
            mat-icon-button
            mat-dialog-close
        >
            <span class="material-icons">close</span>
        </button>
    </header>
    <main>
        <form [formGroup]="privacyForm">
            <div class="settings-card">
                You can use this page to manage all non-essential cookies. For
                more detailed information about the cookies we use, see our
                cookie page. These settings will be saved and associated with
                this browser. Altruist uses cookies to provide you with the best
                possible experience. To deactivate all or certain cookies on
                your device, you may need to use the corresponding functionality
                of your browser.
            </div>
            <div class="settings-card">
                <div class="toggle">
                    <label>Essential (Required)</label>
                    <mat-slide-toggle
                        color="primary"
                        formControlName="essential"
                    ></mat-slide-toggle>
                </div>
                <div class="description">
                    Our sites and services cannot function properly without
                    essential cookies. For example, Altruist may use essential
                    cookies to identify and authenticate users. Altruist also
                    uses essential cookies to enforce the Terms of Use and
                    ensure the security of its sites and services.
                </div>
            </div>
            <div class="settings-card">
                <div class="toggle">
                    <label>Statistics</label>
                    <mat-slide-toggle
                        color="primary"
                        formControlName="statistics"
                    ></mat-slide-toggle>
                </div>
                <div class="description">
                    Analytics cookies collect information about how visitors use
                    our sites and services, for example the pages that users
                    visit most often or error messages received by users on our
                    pages. Analytics cookies do not collect information that can
                    identify a visitor, and the information collected by these
                    cookies is aggregated.
                </div>
            </div>
        </form>
    </main>
    <footer>
        <button
            type="button"
            mat-dialog-close
            matRipple
            class="app-button dialog-button app-button-cancel"
        >
            Cancel
        </button>
        <button
            type="button"
            matRipple
            class="app-button dialog-button app-button-save"
            (click)="savePrivacySettings()"
        >
            Save
        </button>
    </footer>
</div>
