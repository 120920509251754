import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FaqPageComponent } from '../faq-page/faq-page.component';

@Component({
    selector: 'app-survey-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    constructor(private location: Location, public dialog: MatDialog) {}

    goBack(): void {
        this.location.back();
    }

    openFAQ(): void {
        this.dialog.open(FaqPageComponent, {
            width: 'calc(100vw - 32px)',
            maxWidth: '100vw',
            height: 'calc(100vh - 32px)',
            maxHeight: '95vh',
        });
    }
}
