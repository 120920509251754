<div class="popup app-dialog">
    <header>
        <span>&nbsp;</span>
        <button
            type="button"
            class="dialog-close"
            mat-icon-button
            mat-dialog-close
        >
            <span class="material-icons">close</span>
        </button>
    </header>
    <main>
        <form #form="ngForm" id="name-form">
            <mat-form-field class="inputfield" appearance="outline">
                <mat-label>Enter your email</mat-label>
                <input
                    matInput
                    [formControl]="inputControl"
                    [errorStateMatcher]="matcher"
                />
                <mat-error>Please enter a valid email address</mat-error>
            </mat-form-field>
        </form>
    </main>
    <footer>
        <div mat-dialog-actions class="button-component">
            <button mat-button class="send-email-button" (click)="closeClick()">
                Send
            </button>
        </div>
    </footer>
</div>
