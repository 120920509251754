<div class="cookie-policy-banner" [ngClass]="{ open: visible }">
    <div class="content">
        <div class="message">
            <div class="message-header">We use Cookies.</div>
            <div class="message-content">
                Cookies help us deliver the best experience on our website. By
                using our website you are agreeing to the use of cookies.
                <a
                    href="https://g2.getterms.io/view/Jgnra/cookie/en-us"
                    target="_blank"
                    >Find out how we use cookies.</a
                >
            </div>
        </div>
        <div class="actions">
            <button
                type="button"
                mat-dialog-close
                matRipple
                class="app-button dialog-button app-button-cancel"
                (click)="openPrivacySettings()"
            >
                Settings
            </button>
            <button
                type="button"
                matRipple
                class="app-button dialog-button app-button-save"
                (click)="acceptAllCookies()"
            >
                Accept
            </button>
            <div class="desktop-banner-close">
                <button
                    type="button"
                    class="app-icon-button white dialog-close"
                    (click)="hideBanner()"
                >
                    <span class="material-icons">close</span>
                </button>
            </div>
        </div>
    </div>
    <div class="mobile-banner-close">
        <button
            type="button"
            class="app-icon-button white dialog-close"
            (click)="hideBanner()"
        >
            <span class="material-icons">close</span>
        </button>
    </div>
</div>
