import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss'],
})
export class FaqPageComponent {
    faqs = [
        {
            question: 'What is The Volunteer Story Genie?',
            answer: 'Al is a talented storyteller who possesses a magical ability to weave captivating and silly tales to spread joy through the volunteer and nonprofit workspace.',
        },
        {
            question: 'How does it work?',
            answer: "It's Magic… We don't really know the extent of Al's abilities. Actually we think he harnesses the power of AI to tell his stories.",
        },
        {
            question: 'Do I have to give real answers?',
            answer: 'Nope. Be as silly or anonymous as you like. Just keep it clean.',
        },
        {
            question: 'Do you store my data?',
            answer:
                'Volunteer Story Genie does not store any personally identifiable data. ' +
                'Information will only be collected for marketing purposes if you opt-in to marketing emails.',
        },
        {
            question: 'What other volunteering products do you have?',
            answer:
                'Thanks for asking! We have a couple more great tools and products for you. ' +
                'We would love for you to check them out.' +
                '<br><br> Volunteer ROI Calculator - show the worth of your volunteer workforce (Coming Soon)  ' +
                '<br><br> ' +
                '<a class="link-tag-styles" href="https://www.altruist.dev/" target="_blank">Altruist</a> ' +
                '- A simple and modern volunteer management platform.',
        },
    ];

    constructor(public dialogRef: MatDialogRef<FaqPageComponent>) {}

    closeDialog() {
        this.dialogRef.close();
    }
}
