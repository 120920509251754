import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Subject, filter, takeUntil } from 'rxjs';
import { CookieSettingsComponent } from 'src/app/components/cookie-settings/cookie-settings.component';
import { dialogConfig } from 'src/app/constants/dialog-config';
import { CookiePolicyAgreement } from 'src/app/models/cookie-policy-agreement.model';
import { CookiePolicyService } from 'src/app/services/cookie/cookie-policy.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-cookie-banner',
    templateUrl: './cookie-banner.component.html',
    styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnInit, OnDestroy {
    visible = false;

    private cookiePolicyAgreement: CookiePolicyAgreement | null = null;
    private readonly COOKIE_POLICY_DEFAULT: CookiePolicyAgreement = {
        settings: {
            statistics: true,
        },
    };

    private appInsights: ApplicationInsights | null = null;
    private destroyed$ = new Subject<void>();

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private cookiePolicyService: CookiePolicyService
    ) {}

    ngOnInit(): void {
        this.configureCookiePolicy(
            this.cookiePolicyService.getCookiePolicyAgreement()
        );
        this.cookiePolicyService.cookiePolicyChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((changedAgreement) => {
                this.configureCookiePolicy(changedAgreement);
            });

        this.router.events
            .pipe(
                filter((ev) => ev instanceof NavigationEnd),
                takeUntil(this.destroyed$)
            )
            .subscribe(() => {
                this.visible = !this.cookiePolicyAgreement;
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    acceptAllCookies() {
        this.cookiePolicyService.setCookiePolicyAgreement(
            this.COOKIE_POLICY_DEFAULT
        );
    }

    hideBanner() {
        this.visible = false;
    }

    openPrivacySettings() {
        this.dialog.open(CookieSettingsComponent, {
            ...dialogConfig,
            restoreFocus: false,
        });
    }

    private configureCookiePolicy(agreement: CookiePolicyAgreement | null) {
        // Setup Application Insights
        if (agreement && agreement.settings.statistics && !this.appInsights) {
            this.appInsights = this.getApplicationInsights();
            this.appInsights.loadAppInsights();
        } else {
            this.appInsights?.unload();
            this.appInsights = null;
        }

        this.cookiePolicyAgreement = agreement;
        this.visible = !this.cookiePolicyAgreement;
    }

    private getApplicationInsights() {
        const angularPlugin = new AngularPlugin();
        return new ApplicationInsights({
            config: {
                connectionString: environment.applicationInsights,
                extensions: [angularPlugin],
                extensionConfig: {
                    [angularPlugin.identifier]: { router: this.router },
                },
            },
        });
    }
}
